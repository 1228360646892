<template>
    <Menubar :model="items">
      <template #start>
        <img style="width:40px" alt="logo" src="/tattle-logo-small.png" class="mr-2">
      </template>
      <template #end>
           
      </template>
    </Menubar>
</template>

<script>

export default {
  name: 'NavBar',
  created(){

    let purgeList = []

    if(this.$store.state.isLoggedIn){
      purgeList = [
        "Login / Join"
      ]
    } else {
      purgeList = [
        "Dashboard",
        "Network Queries",
        "Quit"
      ]
    }

    for (var i = this.items.length - 1; i >= 0; i--) {
      let el = this.items[i]

      if(purgeList.indexOf(el.label) !== -1){
        this.items.splice(i, 1)
      }
    }
  },
  data() {
    return {
      items: [
        {
          label:'Home Page',
          icon:'pi pi-fw pi-home',
          to : '/'
        },
        {
          label:'Dashboard',
          icon:'pi pi-fw pi-compass',
          to : '/dashboard'
        },
        {
          label:'Network Queries',
          icon:'pi pi-fw pi-search',
          to : '/network'
        },
        {
          label:'Pricing',
          icon:'pi pi-fw pi-dollar',
          command : () => {

            if(!confirm("You will be redirected to a typeform. This is a survey to determine the ideal price of this service. Do you want to continue?"))
              return

            window.location ="https://forms.gle/CG5Y57b1WkKwWns19"
          }
        },
        {
          label:'Login / Join',
          icon:'pi pi-fw pi-search',
          to : '/login'
        },
        {
          label:'Quit',
          icon:'pi pi-fw pi-power-off',
          command: () => {
            delete window.localStorage["access_token"]
            window.location = "/"
          }
        }
      ]
    }
  }
}
</script>
<style >
  .router-link-active-exact {
    background: #333;
    border-radius: 6px;
  }

  .router-link-active-exact span {
    color:#fff !important;
  }
</style>
