import { createStore } from 'vuex'
import axios from 'axios'


const jwtToken = window.localStorage["access_token"]
const basePath = process.env.VUE_APP_API_URL

const instance = axios.create({
  baseURL: basePath,
  timeout: 45000,
  headers: {
    'ACCESS_TOKEN': jwtToken
  }
});

const params = new URLSearchParams(document.location.search);
const s = params.get("code");


if(s){
  instance.get(`/confirm?code=${s}`)
  .then(res => {
    window.localStorage["access_token"] = res.data.token
    window.location = "/network"
  })
  .catch(err => {
    console.log(err)
  })
}


// Create a new store instance.
const store = createStore({
  state () {
    return {
      isLoggedIn: jwtToken ? true : false,
      service : instance,
      dashboard : null,
      user : null,
      postMap : {},
      chartData : null,
      lineData : null
    }
  },
  mutations: {
    increment (state) {
      state.count++
    },
    updateUser(state, user){
      state.user = user
    },
    pushPosts(state, obj){
      state.postMap[obj.key] = obj.posts

      state.dashboard = {
        total_posts : 0,
        total_eng : 0,
        likes : 0,
        comments : 0,
        influence : 0,
        shares : 0,
        twitter : {
           likes : 0,
           comments : 0,
           shares : 0,
        },
        instagram : {
           likes : 0,
           comments : 0,
           shares : 0,
        },
        reddit : {
           likes : 0,
           comments : 0,
           shares : 0,
        }
      }

      let userParsed = []

      for(let q of state.user.queries){

        let qId = q._id
        
        if(state.postMap[qId]){

          state.dashboard.total_posts += state.postMap[qId].length

          for(let el of state.postMap[qId]){

            if(userParsed.indexOf(el.user?.userid) === -1){

              const infCount = el.user?.influence?.count
              if(infCount)
                state.dashboard
                .influence += infCount

              userParsed.push(el.user?.userid)
            }


              if(el.popularity)
                for(let m of el.popularity){
                  state.dashboard.total_eng += m.count
                  

                  if(m.name == "retweets"){
                    state.dashboard.shares += m.count

                    state.dashboard[el.network]
                    .shares += m.count
                  }

                  if(m.name == "comments"){
                    state.dashboard.comments += m.count
                    
                    state.dashboard[el.network]
                    .comments += m.count
                  }

                  if(m.name == "likes" || m.name == "ups"){
                    state.dashboard.likes += m.count
                    
                    state.dashboard[el.network]
                    .likes += m.count
                  }
              }
            }

          }
      }

      state.chartData = {
          labels: ['Likes','Shares','Comments'],
          datasets: [
              {
                  data: [
                    state.dashboard.likes, 
                    state.dashboard.shares, 
                    state.dashboard.comments,
                  ],
                  backgroundColor: ["#FF6384","#36A2EB","#FFCE56"],
                  hoverBackgroundColor: ["#FF6384","#36A2EB","#FFCE56"]
              }
          ]
      }

      state.lineData = {
        labels: ['Likes', 'Shares', 'Comments'],
        datasets: [
          {
            label: 'Twitter',
            backgroundColor: 'lightblue',
            data: [
              state.dashboard['twitter'].likes,
              state.dashboard['twitter'].shares,
              state.dashboard['twitter'].comments
            ]
          },
          {
            label: 'Instagram',
            backgroundColor: 'limegreen',
            data: [
              state.dashboard['instagram'].likes,
              state.dashboard['instagram'].shares,
              state.dashboard['instagram'].comments
            ]
          },
          {
            label: 'Reddit',
            backgroundColor: 'orangered',
            data: [
              state.dashboard['reddit'].likes,
              state.dashboard['reddit'].shares,
              state.dashboard['reddit'].comments
            ]
          }
        ]
      }

    },
    updateQueries(state, queries){
      state.user.queries = queries

      //update_user
       instance.put(`/update_user`, state.user)
        .then(res => {
           fetchUser()
           setTimeout(() => {
              initFetchPosts()
           }, 15000)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
})

function fetchUser(){
  instance.get(`/me`)
    .then(res => {

      if(!res.data.user.queries){
        res.data.user.queries = []
      }
      store.commit('updateUser', res.data.user)
      initFetchPosts()
    })
    .catch(err => {
      console.log(err)
    })
}

function fetchPosts(queryId){
  instance.get(`/query_posts?code=${queryId}`)
    .then(res => {

      store.commit(
        'pushPosts',
        {
          key : queryId,
          posts : res.data.posts.reverse(),
        }
      )
    })
    .catch(err => {
      console.log(err)
    })
}

function initFetchPosts(){

  if(!store.state.user)
    return

  for(let q of store.state.user.queries){
    fetchPosts(q._id)
  }
}

if(jwtToken){
  fetchUser()

  setInterval(() => {
    initFetchPosts()
  }, 5*60000)
}

export default store