<template>
  <div class="home">
   	<div class="slide-one">
   		<img class="logo" src="/logo-white.png">
   		<br/><a class="p-button" style="width: 113px;margin: 0 auto;" href="#features">
   			Learn More
   		</a>
   		<br/>
   		<br/>
   	</div>

   	<div id="features" class="slide-two">
   		<h3>Features</h3>
   		<hr/>

   		<div class="flex">
			<div class="text-gray-900 w-4" >
				<Panel header="Track Brand Engagement">
					<p>Tattle tracks the comments,likes and shares of posts relevant to your brand. This data is aggregated into an easy to read dashboard.</p>
				</Panel>
		   	</div>
		   	<div class="text-gray-900 w-4" >
				<Panel header="See what people are saying about your brand">
					<p>Tattle will generate a wall of posts relating to your brand. This is useful to see how your brand is represented on social media.</p>
				</Panel>
		   	</div>
		   	<div class="text-gray-900 w-4" >
				<Panel header="Network Queries">
					<p>Tattle's API does not connect to any of your social media accounts. Simply enter your brand's hashtag or profile. Network Queries will do the rest.</p>
				</Panel>
		   	</div>
		</div>
		<h1>Ready to try it out?</h1>
		<p>Click on the button below to start.</p>
   		<a class="cta p-button" href="/login">
   			Begin
   		</a>
  </div>
	</div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
<style scoped>
	.home {
		position: fixed;
	    top: 0;
	    height: 100%;
	    width: 100%;
	    left: 0;
	    overflow-y: auto;
	    overflow-x: hidden;
	}
	.slide-one {
		text-align:center;
		background:#000;
	}

	.slide-one img {
		width:512px;
		margin-top:65px;
	}

	a {
		text-decoration:none;
	}

	.cta {
		font-size:4em;
		width:350px;
		display:inline-block;
		text-transform:uppercase;
		font-weight:normal;
	}
	.slide-two {
		text-align:center;
		padding:20px;
	}

	.text-gray-900 p {
		text-align:left;
	}

	.p-panel {
		margin:20px;
		box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
	}

	@media only screen and (max-width: 600px) {
	 .logo {
	 	width:100% !important;
	 }

	 .flex {
	 	display: block !important;
	 }

	 .w-4 {
	 	width:100% !important;
	 }
	}
</style>
