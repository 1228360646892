import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import NavBar from '@/components/navbar.vue'
import Menubar from 'primevue/menubar';
import Chart from 'primevue/chart';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';
import Message from 'primevue/message';
import Button from 'primevue/button';
import MasonryWall from '@yeger/vue-masonry-wall';
import Vuex from './vuex';
import ProgressSpinner from 'primevue/progressspinner';
import Chip from 'primevue/chip';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBhf5MI2_NtA1h6f3fGUGSEGaeahHRtqiw",
  authDomain: "tattle-370300.firebaseapp.com",
  projectId: "tattle-370300",
  storageBucket: "tattle-370300.appspot.com",
  messagingSenderId: "958967645319",
  appId: "1:958967645319:web:c0c1317b4779c89f9ae422",
  measurementId: "G-64MFNCZCX8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

createApp(App)
.use(router)
.use(Vuex)
.use(PrimeVue)
.use(MasonryWall)
.component("navbar", NavBar)
.component("Menubar", Menubar)
.component("Chart", Chart)
.component("InputText", InputText)
.component("Panel", Panel)
.component("Message", Message)
.component("Button", Button)
.component("Chip", Chip)
.component("ProgressSpinner", ProgressSpinner)
.mount('#app')
